import React from "react";
import { Link } from "../../links";
import { StyledButton } from "./styles";

type PpTransitionalCTALinkPage = {
  buttonText: string;
  to: string;
};

export function TransitionalCTALinkPage({
  buttonText,
  to,
}: PpTransitionalCTALinkPage): React.ReactElement {
  return (
    <Link to={to}>
      <StyledButton variant="contained">{buttonText}</StyledButton>
    </Link>
  );
}
